<template>
  <div>
    <BButton
      id="promo-code"
      v-ripple.300="'rgba(255, 159, 67, 0.15)'"
      class="rounded w-100 px-1"
      variant="outline-primary"
      @click="onTogglePopover(true)"
    >
      <div class="text-body float-left d-flex-center">
        <IAmIcon
          icon="percent"
          size="22"
          class="mr-50"
        />
        <span class="text-nowrap fw-700 font-medium-1">
          {{ promoCodes.length > 0 ? promoCodes.length : '' }}
          {{ $t('flight.promoCode') }}
        </span>
      </div>
    </BButton>

    <BPopover
      id="popover-promo-code"
      ref="refPopover"
      variant="warning"
      target="promo-code"
      placement="top"
      triggers="focus hover"
      boundary="window"
      :show.sync="showPopover"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center bg-warning">
          {{ $t('flight.promoCode') }}
          <span
            class="p-25 cursor-pointer"
            @click="onTogglePopover"
          >
            <feather-icon
              icon="XIcon"
              size="20"
            />
          </span>
        </div>
      </template>

      <BContainer>
        <BRow
          v-for="(airline) in supportAirlines"
          :key="airline"
          class="mb-50 px-0"
        >
          <BCol
            cols="2"
            class="d-flex-center px-0"
          >
            <div>
              {{ airline }}
            </div>
          </BCol>
          <BCol
            cols="8"
            class="px-50"
          >
            <!-- Chuyển sang dùng autosuggest rồi nên ẩn cái cũ đi -->
            <!-- <BFormInput
              :value="promoCodes.find(item => item.airline === airline) ? promoCodes.find(item => item.airline === airline).code : null"
              :placeholder="$t('flight.promoCode')"
              :formatter="upperCaseFormatter"
              style="min-width: 80px;"
              @change="val => handleChange(airline, val)"
            /> -->
            <vue-autosuggest
              :value="promoCodes.find(item => item.airline === airline) ? promoCodes.find(item => item.airline === airline).code : null"
              :suggestions="availablePromotionsAirline[airline]"
              :input-props="{
                id: `promo-code-${airline}`,
                class: `form-control`,
                placeholder: $t('flight.promoCode')
              }"
              :get-suggestion-value="(option) => option.item.code"
              @input="(val) => handleChange(airline, val)"
              @selected="(option) => handleSelect(airline, option)"
            >
              <template slot-scope="{suggestion}">
                <div
                  class="cursor-pointer"
                  style="color: black;"
                >
                  <b>{{ suggestion.item.code }}</b>
                  <div>
                    {{ suggestion.item.description }}
                  </div>
                </div>
              </template>
            </vue-autosuggest>
          </BCol>
          <BCol
            cols="2"
            class="px-0 d-flex-center"
          >
            <BButton
              :disabled="!promoCodes.find(item => item.airline === airline)"
              :variant="!!promoCodes.find(item => item.airline === airline) ? 'flat-danger' : 'flat-secondary'"
              :class="`p-50 ${!!promoCodes.find(item => item.airline === airline) ? 'cursor-pointer' : 'cursor-not-allowed'}`"
              @click="handleChange(airline, '')"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </BButton>
          </BCol>
        </BRow>
      </BContainer>
    </BPopover>
  </div>
</template>

<script>
import {
  BButton,
  BContainer,
  BPopover,
  BRow,
  BCol,
  // BFormInput,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BButton,
    BContainer,
    BPopover,
    BRow,
    BCol,
    // BFormInput,
    VueAutosuggest,
  },
  props: {
    promoCodes: {
      type: [Array, null],
      default: () => [],
    },
    airlines: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { FLIGHT_APP_STORE_MODULE_NAME } = useBookingHandle()

    function handleChange(airline, code) {
      const promoCodesToUpdate = cloneDeep(props.promoCodes)
      const airlineIndex = promoCodesToUpdate.findIndex(item => item.airline === airline)
      if (airlineIndex > -1) {
        if (code.length > 0) {
          promoCodesToUpdate[airlineIndex].code = code
        } else {
          promoCodesToUpdate.splice(airlineIndex, 1)
          // emit('update:airlines', props.airlines.filter(item => item !== airline))
        }
      } else {
        promoCodesToUpdate.push({
          airline,
          code,
        })
        if (!props.airlines.includes(airline)) {
          // emit('update:airlines', [...props.airlines, airline])
        }
      }
      emit('update:promoCodes', promoCodesToUpdate)
    }

    const handleSelect = (airline, option) => {
      if (option?.item) {
        handleChange(airline, option.item.code)
      }
    }

    const supportAirlines = ref(['QH', 'VN', 'VJ', 'VU', 'AK'])

    const availablePromotionsAirline = ref(supportAirlines.value.reduce((acc, airline) => ({ ...acc, [airline]: [] }), {}))

    // store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchPromotionAirlineAvailable`, {}).then(res => {
    //   res.data.items.sort((a, b) => b.priority - a.priority).forEach(promotion => {
    //     const airline = promotion.airline
    //     if (availablePromotionsAirline.value[airline]) {
    //       availablePromotionsAirline.value[airline].push({ data: [promotion] })
    //     }
    //   })
    //   const promoCodes = supportAirlines.value.reduce((codes, airline) => {
    //     if (availablePromotionsAirline.value[airline][0]) {
    //       const promotion = availablePromotionsAirline.value[airline][0].data[0]
    //       codes.push({ airline, code: promotion.code })
    //     }
    //     return codes
    //   }, [])
    //   emit('update:promoCodes', promoCodes)
    // })

    // ANCHOR handle popover
    const showPopover = ref(false)
    const onTogglePopover = (status = null) => {
      if (status === true || status === false) {
        showPopover.value = status
        return
      }
      showPopover.value = !showPopover.value
    }

    return {
      showPopover,
      onTogglePopover,
      supportAirlines,
      availablePromotionsAirline,
      handleChange,
      handleSelect,
      upperCaseFormatter,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
#autosuggest-autosuggest__results {
  .autosuggest__results {
    width: 400px
  }
}
</style>
